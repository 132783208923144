<template>
  <v-dialog v-model="cargando" persistent width="600">
    <v-card>
      <v-card-title class="titleslight--text">
        Generar archivo Excel
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center" dense>
            <v-col sm="6" md="6" lg="6" xl="6">
              <v-menu
                ref="desde"
                v-model="desde"
                :close-on-content-click="false"
                :return-value.sync="datedesde"
                transition="scale-transition"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datedesde"
                    label="Desde"
                    prepend-icon="fas fa-calendar-alt"
                    readonly
                    :rules="aValidateDesde"
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="datedesde"
                  no-title
                  scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="desde = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.desde.save(datedesde)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="6" md="6" lg="6" xl="6">
              <v-menu
                ref="hasta"
                v-model="hasta"
                :close-on-content-click="false"
                :return-value.sync="datehasta"
                transition="scale-transition"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datehasta"
                    label="Hasta"
                    prepend-icon="fas fa-calendar-alt"
                    readonly
                    :rules="aValidateHasta"
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="datehasta"
                  no-title
                  scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="hasta = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.hasta.save(datehasta)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-progress-linear
          active
          :indeterminate="bFinish"
          color="primary"
          class="mb-0"
          height="20"
          rounded>
          <strong>{{ sLabel }}</strong>
        </v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          small
          color="primary"
          class="ma-2"
          :disabled="bGenerate"
          @click="getDataExport()">
          <v-icon x-small left dark>fa-file-excel</v-icon>
          Generar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          color="primary"
          class="ma-2"
          :disabled="!bGenerate"
          @click="onExport()">
          <v-icon x-small left dark>fas fa-cloud-download-alt</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import XLSX from 'xlsx'
import axiosServices from '@/services/axiosServices.js'
export default {
  data() {
    return {
      sLabel  : '',
      bFinish : false,
      bGenerate: false,
      cargando: true,
      json_meta   : [
        [{'key': 'charset', 'value': 'utf-8'}]
      ],
      aHeadersPQR: [],
      aItemsPQR: [],
      aHeadersHistory : [],
      aItemsHistory: [],
      datedesde: '',
      datehasta: '',
      desde: false,
      hasta: false,
      aValidateDesde: [
        v => !!v || 'La fecha desde es requerida.'
      ],
      aValidateHasta: [
        v => !!v || 'La fecha hasta es requerida.',
        v => Date.parse(v) >= Date.parse(this.datedesde) || 'La fecha hasta no puede ser menor a la fecha desde'
      ]
    }
  },
  methods: {
    getDataExport() {
      if (this.datedesde !== "" && this.datehasta !== "" && (Date.parse(this.datehasta) >= Date.parse(this.datedesde))) {
        this.sLabel =  'Generando...'
        this.bFinish = true
        let sParams = `datedesde=${this.datedesde}&datehasta=${this.datehasta}`
        axiosServices.getConfiguration('FormDinamicPQR_getexport', true, sParams).then(aDataExport => {
          if (aDataExport.nStatusCode == 200) {
            this.aHeadersPQR = aDataExport.aData.headers
            this.aItemsPQR = aDataExport.aData.information
            this.getDataHistory()
          } else {
            this.$store.dispatch('notificaciones/SetMensaje', `${aDataExport.sMessage}`)
            this.$store.dispatch('notificaciones/SetColor','error')
            this.$store.dispatch('notificaciones/ShowNotificacion', true)
          }
        })
      } else {
        this.$store.dispatch('notificaciones/SetMensaje', 'Debe seleccionar un rango de fecha ó la fecha hasta es menor a la fecha desde.')
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true)
      }
    },
    getDataHistory() {
      let sParams = `datedesde=${this.datedesde}&datehasta=${this.datehasta}`
      axiosServices.getConfiguration('/FormDinamicPQR_getexporthist', true, sParams).then(aDataExport => {
        if (aDataExport.nStatusCode == 200) {
          this.aHeadersHistory = aDataExport.aData.headers
          this.aItemsHistory = aDataExport.aData.information
          this.bFinish = false
          this.bGenerate = true
          this.sLabel = "Listo para descargar!"
        } else {
          this.$store.dispatch('notificaciones/SetMensaje', `${aDataExport.sMessage}`)
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion', true)
        }
      })
    },
    onExport() {
      // export json to Worksheet of Excel
      // only array possible
      var SheetPQR = XLSX.utils.json_to_sheet(this.aItemsPQR, {header: this.aHeadersPQR})
      var SheetHistory = XLSX.utils.json_to_sheet((typeof this.aItemsHistory == "object") ? Object.values(this.aItemsHistory) : this.aItemsHistory, {header: this.aHeadersHistory})
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, SheetPQR, 'Requerimientos')// sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, SheetHistory, 'Trazabilidad')// sheetAName is name of Worksheet
      // export Excel file
      XLSX.writeFile(wb, 'Requerimientos.xlsx') // name of the file
      this.cargando = false
      this.CloseDialog()
    },
    CloseDialog() {
      window.parent.close()
    }
  }
}
</script>